import AWS from 'aws-sdk'
import { Auth } from '@aws-amplify/auth'
import { AWS_REGION, COGNITO_IDENTITY_POOL_ID, COGNITO_IDENTITY_POOL_DOMAIN } from './config'

// users are authenticated on page load. This function uses the
// authenticated identity token to get temporary AWS credentials
// that allow the user to query dynamodb
const getAWSCredentials = async () => {
  const session = await Auth.currentSession()
  AWS.config.region = AWS_REGION
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
    Logins: {
      [COGNITO_IDENTITY_POOL_DOMAIN]: session.getIdToken().getJwtToken(),
    },
  })
  // @ts-ignore
  await AWS.config.credentials.getPromise()
  return AWS.config.credentials
}

let ddbClient: any = null
const getDynamoDBClient = async () => {
  if (ddbClient) return ddbClient

  const credentials = await getAWSCredentials()
  ddbClient = new AWS.DynamoDB.DocumentClient({
    region: AWS_REGION,
    credentials,
  })
  return ddbClient
}

export default getDynamoDBClient
