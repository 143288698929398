import React, { useState, useEffect } from 'react'
import { Auth } from '@aws-amplify/auth'
import Table from './Table'
import {
  AWS_REGION,
  COGNITO_USER_POOL_DOMAIN,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_USER_POOL_CLIENT_ID,
  COGNITO_USER_POOL_ID,
} from './config'

Auth.configure({
  region: AWS_REGION,
  userPoolId: COGNITO_USER_POOL_ID,
  userPoolWebClientId: COGNITO_USER_POOL_CLIENT_ID,
  IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
  oauth: {
    domain: COGNITO_USER_POOL_DOMAIN,
    scope: ['openid'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code',
  },
})

function App() {
  const [signedIn, setSignedIn] = useState(false)

  useEffect(() => {
    const handler = async () => {
      try {
        await Auth.currentAuthenticatedUser()
        setSignedIn(true)
      } catch (e) {
        setSignedIn(false)
        console.log(e)
        await Auth.federatedSignIn()
      }
    }
    handler()
  }, [])

  return (
    <>
      <div
        style={{
          backgroundColor: '#0f344c',
          marginBottom: '1em',
          padding: '2rem',
          boxShadow: '0 5px 10px rgba(0,0,0,.3)',
          display: 'flex',
          alignItems: 'center',
          fontFamily: '"Amazon Ember","Helvetica Neue",Roboto,Arial,sans-serif',
          fontSize: '1.4rem',
        }}
      >
        <div
          style={{
            fontSize: '2em',
            color: '#fff',
            fontWeight: 800,
            flex: '1 1 100%',
          }}
        >
          OFA Data Integrator Dashboard
        </div>
        <div style={{ textAlign: 'right' }}>
          <a
            href="https://djs.amazon.com/jobs/Data-Integrator-Tool-Prod/history"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#fff',
              textDecoration: 'underline',
              whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            DJS Job History
          </a>
          <a
            href="https://w.amazon.com/bin/view/FinanceTechnology/ReceivablesBilling/FinTechARRemovalsBillingLoadJob/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#fff',
              textDecoration: 'underline',
              whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            ODI Wiki
          </a>
        </div>
      </div>
      <div className="awsui awsui-motion" style={{ padding: '1em' }}>
        {signedIn}
        <Table />
      </div>
    </>
  )
}

export default App
