import React, { useState, useEffect } from 'react'
import {
  Table,
  TablePropertyFiltering,
  Button,
  Icon,
  TablePagination,
  TableSorting,
} from '@amzn/awsui-components-react/polaris'
import getDynamoDBClient from './getDynamoDBClient'
import { DYNAMODB_JOBS_TABLE_NAME, DYNAMODB_JOBS_TABLE_MONTH_JOBSTARTTIME_INDEX_NAME } from './config'

interface LogEntry {
  parcel?: string
  DatasetDate?: string
  description?: string
  destinationTable?: string
  isSucceeded?: boolean
  status: string
  jobEndTime?: string
  jobHistory?: string
  jobId?: string
  jobStartTime?: string
  legalEntity?: string
  loadType?: string
  parcelDate?: string
  recordsInserted?: number
  recordsRetrieved?: number
}

const TableComponent = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [items, setItems] = useState<LogEntry[]>([])
  const [filteringTokens, setFilteringTokens] = useState<TablePropertyFiltering.FilteringToken[]>([monthFilter])

  const filteringOptions = getFilteringOptions(items)

  // when the month filter, fetch new data
  const month = (filteringTokens.find((token) => token.propertyKey === 'month') || monthFilter).value
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const ddb = await getDynamoDBClient()

        // get all log entries for the month
        const { Items } = await ddb
          .query({
            TableName: DYNAMODB_JOBS_TABLE_NAME,
            IndexName: DYNAMODB_JOBS_TABLE_MONTH_JOBSTARTTIME_INDEX_NAME,
            KeyConditions: {
              month: {
                ComparisonOperator: 'EQ',
                AttributeValueList: [month],
              },
            },
          })
          .promise()

        // turn the status into text to make the property filter work seemlessly
        const enrichedItems = Items.map((item: any) => ({ ...item, status: item.isSucceeded ? 'Succeeded' : 'Failed' }))
        setItems(enrichedItems)
        setLoading(false)
      } catch (e) {
        setError(true)
        setItems([])
        setLoading(false)
      }
    }
    fetchData()
  }, [month])

  return (
    <>
      <Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={false}
        loading={loading}
        features={['propertyFiltering', 'pagination', 'sorting']}
        header={<h2>Job Run Log</h2>}
        empty={
          error ? (
            <span className="awsui-util-status-negative">
              <Icon name="status-negative" /> Error loading data. Try refreshing the page.
            </span>
          ) : (
            <div className="awsui-util-pt-s">
              <p>No jobs found for the current filter.</p>
              <div className="awsui-util-mb-m">
                <Button onClick={() => setFilteringTokens([monthFilter])}>Clear filter</Button>
              </div>
            </div>
          )
        }
      >
        <TablePagination pageSize={25} />
        <TableSorting
          sortingDescending={true}
          sortingColumn={'jobStartTime'}
          sortableColumns={columnDefinitions.map((column) => ({ id: column.id!, field: column.id }))}
        />
        <TablePropertyFiltering
          tokens={filteringTokens}
          filteringOptions={filteringOptions}
          groupPropertiesText="Properties"
          clearFiltersText="Clear filter"
          placeholder="Filter jobs by property"
          onPropertyFilteringChange={(e) => {
            // Only keep the last filter token in the array for each property key.
            // When a user adds a filter token that overlaps with an existing filter
            // token, the old filter token will be removed.
            const nextTokens = [monthFilter, ...e.detail.tokens].filter(
              (token, i, tokens) => tokens.map((t) => t.propertyKey).lastIndexOf(token.propertyKey) === i
            )
            setFilteringTokens(nextTokens)
          }}
          allowFreeTextFiltering={true}
          hideOperations
        ></TablePropertyFiltering>
      </Table>
    </>
  )
}

// generate a list of month strings: ['2020-08', '2020-07', ...]
const months: string[] = []
for (let date = new Date(); date.getFullYear() > 2018; date.setMonth(date.getMonth() - 1))
  months.push(`${date.getFullYear()}/${(date.getMonth() + 1 + '').padStart(2, '0')}`)

const monthFilter: TablePropertyFiltering.FilteringToken = {
  propertyKey: 'month',
  propertyLabel: 'Month',
  value: months[0],
  label: months[0],
  negated: false,
}

const renderTimestamp = (timestamp?: string): string => {
  if (!timestamp) return ''
  const date = new Date(parseFloat(timestamp))
  return date.toISOString()
}

const columnDefinitions: Table.ColumnDefinition[] = [
  { id: 'DatasetDate', header: 'Dataset Date' },
  {
    id: 'status',
    header: 'Status',
    render: (item: LogEntry) =>
      item.status === 'Succeeded' ? (
        <span className="awsui-util-status-positive">
          <Icon name="status-positive" /> Succeeded
        </span>
      ) : (
        <span className="awsui-util-status-negative">
          <Icon name="status-negative" /> Failed
        </span>
      ),
  },
  { id: 'destinationTable', header: 'Destination Table' },
  { id: 'legalEntity', header: 'Legal Entity' },
  { id: 'loadType', header: 'Load Type' },
  {
    id: 'records',
    header: 'Records Retrieved / Inserted',
    render: (item: LogEntry) => `${item.recordsRetrieved} / ${item.recordsInserted}`,
  },
  { id: 'parcelDate', header: 'Parcel Date' },
  {
    id: 'jobStartTime',
    header: 'Job Start Time',
    render: (item: LogEntry) => renderTimestamp(item.jobStartTime),
  },
  {
    id: 'jobEndTime',
    header: 'Job End Time',
    render: (item: LogEntry) => renderTimestamp(item.jobStartTime),
  },
  { id: 'description', header: 'Description' },
].map((c: { id: string; header: string; render?: (item: LogEntry) => any }) => ({
  ...c,
  cell: (item: LogEntry) => (c.render ? c.render(item) : item[c.id as keyof LogEntry]),
}))

const getFilteringOptions = (items: LogEntry[]): TablePropertyFiltering.Option[] => {
  // gets a list of unique values to filter by for a given log entry key
  const getUniqueValues = (key: keyof LogEntry): string[] => {
    const uniqueValues = Array.from(items.reduce((set, item: LogEntry) => set.add(item[key]), new Set())) as string[]
    return uniqueValues.sort((a, b) => a.localeCompare(b))
  }

  return [
    {
      propertyLabel: 'Month',
      propertyKey: 'month',
      groupValuesLabel: 'Months',
      values: months,
    },
    {
      propertyLabel: 'Status',
      propertyKey: 'status',
      groupValuesLabel: 'Statuses',
      values: ['Succeeded', 'Failed'],
    },
    {
      propertyLabel: 'Load Type',
      propertyKey: 'loadType',
      groupValuesLabel: 'Load Types',
      values: getUniqueValues('loadType'),
    },
    {
      propertyLabel: 'Dataset Date',
      propertyKey: 'DatasetDate',
      groupValuesLabel: 'Dataset Dates',
      values: getUniqueValues('DatasetDate'),
    },
    {
      propertyLabel: 'Destination Table',
      propertyKey: 'destinationTable',
      groupValuesLabel: 'Destination Tables',
      values: getUniqueValues('destinationTable'),
    },
    {
      propertyLabel: 'Legal Entity',
      propertyKey: 'legalEntity',
      groupValuesLabel: 'Legal Entities',
      values: getUniqueValues('legalEntity'),
    },
  ]
}

export default TableComponent
